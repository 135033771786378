import Vue from 'vue';

import '@/plugins/axios';
import '@/plugins/casl';
import '@/plugins/vee-validate';
import '@/plugins/clipboard';
import '@/plugins/google.maps';
import '@/plugins/dayjs';

import store from '@/store';
import router from '@/router';

import vuetify from '@/plugins/vuetify';

import Ampifire from '@/Ampifire.vue';
import ClientCabin from '@/ClientCabin.vue';

import { isClientCabin } from './utils/helpers';

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: createElement =>
        createElement(isClientCabin() ? ClientCabin : Ampifire)
});
