import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faAlignLeft,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faAngleLeft,
    faAngleRight,
    faArrowCircleUp,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBars,
    faBook,
    faBoxArchive,
    faBoxOpen,
    faBriefcase,
    faBuilding,
    faCalendar,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCertificate,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleExclamation,
    faCircleQuestion,
    faClock,
    faClose,
    faCoins,
    faComment,
    faCompress,
    faCopy,
    faCreditCard,
    faCrop,
    faCropSimple,
    faCrown,
    faDownload,
    faEdit,
    faEllipsisV,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileContract,
    faFileImage,
    faFilm,
    faFilter,
    faFlag,
    faFolder,
    faFolderOpen,
    faGem,
    faGear,
    faGlobe,
    faHeadphones,
    faImage,
    faInbox,
    faInfoCircle,
    faKey,
    faLock,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faMoneyBill,
    faMoon,
    faPaperPlane,
    faParagraph,
    faPause,
    faPencilAlt,
    faPen,
    faPenNib,
    faPenSquare,
    faPhone,
    faPhotoVideo,
    faPlay,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faProjectDiagram,
    faRepeat,
    faRetweet,
    faRotate,
    faRotateLeft,
    faRotateRight,
    faRssSquare,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSquare,
    faStar,
    faSun,
    faSync,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTv,
    faUser,
    faUserCircle,
    faUsers,
    faUserShield,
    faUserSlash,
    faVideo,
    faAt,
    faFileCsv,
    faFilePdf,
    faFireFlameCurved,
    faLinkSlash,
    faCommentDollar,
    faM,
    faB,
    faPalette,
    faChartPie,
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faAlignLeft,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faAngleLeft,
    faAngleRight,
    faArrowCircleUp,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBars,
    faBook,
    faBoxArchive,
    faBoxOpen,
    faBriefcase,
    faBuilding,
    faCalendar,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCertificate,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleExclamation,
    faCircleQuestion,
    faClock,
    faClose,
    faComment,
    faCompress,
    faCopy,
    faCreditCard,
    faCrop,
    faCropSimple,
    faCrown,
    faDownload,
    faEdit,
    faEllipsisV,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faEye,
    faEyeSlash,
    faFile,
    faFilter,
    faFileAlt,
    faFileContract,
    faFileImage,
    faFilm,
    faFlag,
    faFolder,
    faFolderOpen,
    faGem,
    faGear,
    faGlobe,
    faHeadphones,
    faImage,
    faInbox,
    faInfoCircle,
    faKey,
    faLock,
    faMoneyBill,
    faMoon,
    faPalette,
    faPaperPlane,
    faParagraph,
    faPause,
    faPencilAlt,
    faPen,
    faPenNib,
    faPenSquare,
    faPhone,
    faPhotoVideo,
    faPlay,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faProjectDiagram,
    faRepeat,
    faRetweet,
    faRotate,
    faRssSquare,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSquare,
    faStar,
    faSun,
    faSync,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTv,
    faUser,
    faUserCircle,
    faUsers,
    faUserShield,
    faUserSlash,
    faVideo,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faRotateLeft,
    faRotateRight,
    faAt,
    faCoins,
    faFileCsv,
    faFilePdf,
    faFireFlameCurved,
    faLinkSlash,
    faCommentDollar,
    faM,
    faB,
    faChartPie,
    faCircleCheck
);
