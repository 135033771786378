<template>
    <v-app-bar
        app
        fixed
        elevate-on-scroll
        :color="barBackgroundColor"
        class="header"
    >
        <loading />

        <v-container
            class="header-container"
            :fluid="$vuetify.breakpoint.mdAndDown"
        >
            <v-row class="header-wrapper" align="center">
                <v-col cols="auto" class="d-flex align-center logo-container">
                    <router-link to="/">
                        <v-img
                            v-if="isDefaultLogo"
                            :alt="logo.alt"
                            src="@/assets/img/logo.ampifire.com.svg"
                            max-width="160"
                        />
                        <v-img
                            v-else
                            :src="logo.src"
                            :alt="logo.alt"
                            position="top left"
                            max-height="32"
                            max-width="180"
                            contain
                        />
                    </router-link>
                </v-col>

                <v-col
                    v-if="$vuetify.breakpoint.mdAndUp"
                    cols="auto"
                    class="navbar-container"
                >
                    <v-list
                        class="d-inline-flex navbar-wrapper"
                        :color="barBackgroundColor"
                    >
                        <v-list-item
                            v-for="(tab, i) in availableTabs"
                            :key="`tab-${i}`"
                            :to="tab.to"
                            :href="tab.href"
                            class="navbar-item"
                            @click="action(tab)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ tab.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>

                <v-col class="pa-0 d-flex justify-end">
                    <v-menu offset-y bottom left>
                        <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" plain rounded v-on="on">
                                <div
                                    v-if="$vuetify.breakpoint.mdAndUp"
                                    class="d-flex align-baseline"
                                >
                                    {{ truncate(firstname, 15) }}
                                    <v-icon right x-small>chevron-down</v-icon>
                                </div>
                                <v-icon v-else>bars</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item-group
                                v-if="$vuetify.breakpoint.smAndDown"
                                class="navbar-menu-container"
                            >
                                <v-list-item
                                    v-for="(tab, i) in availableTabs"
                                    :key="`tab-${i}`"
                                    :to="tab.to"
                                    :href="tab.href"
                                    class="navbar-item"
                                    @click="action(tab)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ tab.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                            <v-divider v-if="$vuetify.breakpoint.smAndDown" />
                            <v-list-item-group>
                                <div
                                    v-if="$vuetify.breakpoint.smAndDown"
                                    class="font-weight-bold pt-2 px-4 text-right"
                                >
                                    {{ truncate(firstname, 15) }}
                                </div>

                                <v-list-item
                                    v-for="(item, i) in menu"
                                    :key="`menu-${i}`"
                                    :to="item.to"
                                    @click="action(item)"
                                >
                                    <v-list-item-icon class="mr-2 align-center">
                                        <v-icon small>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </v-container>

        <support-form-modal />
    </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapGetters } from 'vuex';

import { truncate, isClientCabin } from '@/utils/helpers';

import { Loading } from '@/components/Loading';
import { SupportFormModal } from '@/components/SupportForm';

@Component({
    computed: {
        ...mapState('user', ['firstname', 'isAsigoBuyer', 'isDropServeBuyer']),
        ...mapGetters('logo', ['logo']),
        ...mapGetters('user', [
            'isAuthor',
            'isEditor',
            'isAdmin',
            'isReseller',
            'isClient'
        ])
    },
    components: {
        Loading,
        SupportFormModal
    }
})
export default class AmpifireHeader extends Vue {
    firstname!: string; // mapState,
    logo!: { src: string; alt: string };

    isAdmin!: boolean;
    isAuthor!: boolean;
    isEditor!: boolean;
    isReseller!: boolean;
    isClient!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;

    isDefaultLogo = !isClientCabin();
    logoDescription = '';

    get tabs() {
        return [
            {
                title: 'Welcome',
                to: '/welcome',
                visible: this.$can('view', 'Welcome')
            },
            {
                title: 'Amps',
                to: '/announcements',
                visible: this.$can('view', 'Announcements')
            },
            {
                title: 'Requests',
                to: this.isClient
                    ? '/author-requests/list'
                    : '/author-requests',
                visible:
                    !(this.isAuthor || this.isEditor) &&
                    this.$can('view', 'AuthorRequests')
            },
            {
                title: 'Requests',
                to: '/author-requests-pool',
                visible:
                    (this.isAuthor || this.isEditor) &&
                    !this.isAdmin &&
                    this.$can('view', 'AuthorRequests')
            },
            // Admins can see both
            {
                title: 'Requests',
                to: '/author-requests/list',
                visible: this.isAdmin
            },
            {
                title: 'Request Pool',
                to: '/author-requests-pool',
                visible: this.isAdmin
            },
            {
                title: 'Training',
                href: '/training',
                visible: this.$can('view', 'Training')
            },
            {
                title: 'Sales Seeker',
                href: '/reseller_email_campaigns',
                visible: this.couldStillUseSalesSeeker
            },
            {
                title: 'Amp Sales Funnel',
                to: '/info',
                visible: this.isReseller
            },
            {
                title: 'Support',
                action: () => this.openSupportModal(),
                visible: true
            }
        ];
    }

    menu = [
        {
            title: 'Account',
            icon: 'user-circle',
            to: '/profile/personal'
        },
        {
            title: 'Logout',
            icon: 'sign-out-alt',
            action: () => this.logout()
        }
    ];

    get availableTabs() {
        return this.tabs.filter(tab => tab.visible);
    }

    get barBackgroundColor(): string {
        if (this.$route.path === '/welcome') {
            return '#F4FAFF';
        }

        return 'white';
    }

    get couldStillUseSalesSeeker() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    created() {
        this.$store.dispatch('analytics/identifyUser');
        this.getLogo();
    }

    mounted() {
        const params = new URLSearchParams(window.location.search);
        if (params.get('support') === '1') {
            this.openSupportModal();
        }
    }

    openSupportModal() {
        this.$store.dispatch('modal/open', 'support-form-modal');
    }

    async getLogo() {
        this.$http.get('/users/get_logo').then(({ data }) => {
            this.isDefaultLogo = `${data.data?.logo?.file}`.includes(
                'logo.ampifire.com.svg'
            );

            this.$store.commit('logo/update', {
                src: data.data?.logo?.file,
                alt: data.data?.site
            });

            if (isClientCabin()) {
                this.setFavicon(data.data?.logo?.file);
            }
        });
    }

    setFavicon(src: string) {
        const link: HTMLLinkElement | null =
            document.querySelector("link[rel~='icon']");

        if (link) {
            link.href = src;
        }
    }

    async logout() {
        await this.$store.dispatch('user/logout');

        this.$router.push('/login');
    }

    action(item: { action?: () => void }) {
        if (typeof item.action === 'function') {
            item.action();
        }
    }

    truncate = truncate;
}
</script>

<style lang="scss" scoped>
.header::v-deep {
    height: 64px !important;

    .v-toolbar__content {
        align-items: start;
        padding: 0 !important;

        .header-container {
            height: 100%;
            padding: 0 !important;

            .header-wrapper {
                height: 100%;
                margin: 0;

                .logo-container {
                    width: 180px;
                }

                .navbar-container {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    height: 100%;

                    .navbar-wrapper {
                        height: 100%;
                        padding: 0;

                        .navbar-item {
                            border-top: 4px solid transparent;

                            transition: 0.1s ease-in;

                            &:hover {
                                border-top: 4px solid $primary-color !important;
                            }

                            .v-list-item__content {
                                margin-top: -4px;
                            }

                            &.v-list-item--active {
                                background-color: $secondary-color;
                                border-top: 4px solid $primary-color;
                                border-radius: 0 0 4px 4px;

                                .v-list-item__content {
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }

                .v-btn__content {
                    text-transform: none;
                }
            }
        }
    }
}

.navbar-menu-container {
    .navbar-item {
        border-left: 4px solid transparent;

        transition: 0.1s ease-in;

        &:hover {
            border-left: 4px solid $primary-color !important;
        }

        &.v-list-item--active {
            background-color: $secondary-color;
            border-left: 4px solid $primary-color;

            .v-list-item__content {
                color: $white !important;
            }
        }
    }
}
</style>
