<template>
    <v-app>
        <authorized>
            <ampifire-header />
        </authorized>

        <!-- Sizes your content based upon application components -->
        <v-main :key="cacheKey" class="main-background">
            <!-- Provides the application the proper gutter -->
            <!-- If using vue-router -->
            <system-messages class="py-0" />
            <keep-alive :include="alive">
                <slot></slot>
            </keep-alive>
            <toast-notification />
        </v-main>
        <cookie-consent />
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import AmpifireHeader from '@/layouts/Ampifire/AmpifireHeader.vue';
import { CookieConsent } from '@/components/CookieConsent';
import { ToastNotification } from '@/components/ToastNotification';
import { Authorized } from '@/components/Authorized';
import { SystemMessages } from '@/components/SystemMessages';

@Component({
    components: {
        AmpifireHeader,
        CookieConsent,
        ToastNotification,
        Authorized,
        SystemMessages
    }
})
export default class AmpifireLayout extends Vue {
    // list of component names to cache
    alive = ['Publish'];

    get cacheKey() {
        return this.$store.getters['cachekey/get'](
            this.$route.params.announcementId
        );
    }
}
</script>
